import { v4 as uuid } from "uuid";
import PriceRow from "./PriceRow";

const CurrentSales = ({ currentSales }) => {
    return currentSales.map((sale, index) => {
        const id = uuid();
        return <PriceRow key={id} id={id} sale={sale} index={index} />;
    });
};

export default CurrentSales;
