import { useData, useDispatch } from "../../../store/DataProvider";
import { handleApiData, handleLocalData } from "../../../store/actions";

const SubmitRow = () => {
    const dispatch = useDispatch();
    const data = useData();
    const { paymentType, currentSales, staff, selectedStaff } = data;

    if (currentSales.length === 0) {
        return null;
    }

    const onClickSubmit = e => {
        e.preventDefault();
        if (paymentType) {
            const items = currentSales.reduce((acc, { serviceId, price }) => {
                acc.push({ serviceId, price });
                return acc;
            }, []);
            const commission = staff.reduce((acc, { _id, commission }) => {
                if (_id === selectedStaff) {
                    acc = commission;
                }
                return acc;
            }, 0);
            const sale = {
                staffId: selectedStaff,
                total,
                commission,
                paymentType,
                items,
            };
            handleApiData(sale, dispatch, "submitSale");
        } else {
            handleLocalData(
                { type: "alertPaymentType", payload: true },
                dispatch,
            );
        }
    };

    const total = currentSales.reduce((acc, { price }) => {
        acc += price;
        return acc;
    }, 0);

    return (
        <>
            <div
                style={{
                    height: "20px",
                    borderBottom: "3px dotted #a802a0",
                    marginLeft: "25px",
                    marginRight: "25px",
                }}
            />
            <div className="price-row">
                <input
                    className="price-input submit-row"
                    type="text"
                    value={total}
                    disabled
                />
                <span className="price-label-thai submit-row">
                    Total Amount
                </span>
                <button
                    type="button"
                    className="submit-sale"
                    onClick={onClickSubmit}
                >
                    Enter
                </button>
            </div>
        </>
    );
};

export default SubmitRow;
