import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import Modal from "react-modal";
import { useData, useDispatch } from "../../store/DataProvider";
import { handleApiData, handleLocalData } from "../../store/actions";

import spinner from "../../spinner.gif";

const Authenticate = () => {
    const { token, loggedOut, modalStyle, userAlert } = useData();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cookies = new Cookies(null, { path: "/" });

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userAlert) {
            const message = userAlert;
            handleLocalData({ type: "userAlert", payload: "" }, dispatch);
            window.alert(message);
            setLoading(false);
            return;
        }
    }, [userAlert, dispatch]);

    useEffect(() => {
        if (token && !loggedOut) {
            cookies.set("auth", token, {
                path: "/",
                expires: undefined,
                secure: process.env.NODE_ENV === "production",
            });
            navigate("/");
        }
    }, [token, loggedOut]);

    const onUsernameChange = e => {
        const value = e.target.value;
        setUsername(value);
    };

    const onPasswordChange = e => {
        const value = e.target.value;
        setPassword(value);
        setDisabled(value.length < 6);
    };

    const onSubmitForm = e => {
        e.preventDefault();
        setLoading(true);
        handleApiData({ username, password }, dispatch, "getUser");
    };

    return loading ? (
        <div
            style={{
                margin: "auto auto auto auto",
            }}
        >
            <img
                src={spinner}
                alt=""
                style={{ height: "150px", width: "150px" }}
            />
        </div>
    ) : (
        <Modal
            isOpen={true}
            style={modalStyle}
            contentLabel="Login"
            ariaHideApp={false}
        >
            <form onSubmit={onSubmitForm}>
                <div className="password-row">
                    <div>
                        <span className="password-label submit-password">
                            Username:
                        </span>
                        <input
                            className="password-input submit-password"
                            type="text"
                            value={username}
                            onChange={onUsernameChange}
                            autoFocus={true}
                        />
                    </div>
                    <div>
                        <span className="password-label submit-password">
                            Password:
                        </span>
                        <input
                            className="password-input submit-password"
                            type="password"
                            value={password}
                            onChange={onPasswordChange}
                        />
                    </div>
                    <button
                        type="submit"
                        className="submit-password"
                        onClick={onSubmitForm}
                        disabled={disabled}
                        style={{
                            opacity: disabled ? 0.3 : 1,
                            cursor: disabled ? "default" : "pointer",
                        }}
                    >
                        Enter
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default Authenticate;
