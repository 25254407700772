import { useState, useEffect } from "react";
import { animated, useSpring } from "@react-spring/web";

const Animator = ({ activate, resetAction, children }) => {
    const [animate, setAnimate] = useState(false);

    const timeouter = (value, time) => {
        setTimeout(() => {
            setAnimate(value);
        }, time);
    };

    useEffect(() => {
        if (activate) {
            const timeouts = [0, 300, 500, 700, 900, 1100];
            let bool = true;
            timeouts.forEach(time => {
                timeouter(bool, time);
                bool = !bool;
            });
            resetAction(false);
        }
    }, [activate, resetAction]);

    const styles = useSpring({
        opacity: animate ? 0.2 : 1,
    });

    return <animated.div style={styles}>{children}</animated.div>;
};

export default Animator;
