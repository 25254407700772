import { useState } from "react";
import Datatable from "./Datatable";
import { handleApiData, initData } from "../../store/actions";
import { useDispatch } from "../../store/DataProvider";

const ExpenseTypes = ({ expenseTypesDt }) => {
    const dispatch = useDispatch();

    const [selectedExpenseTypeId, setSelectedExpenseTypeId] = useState();
    const [thai, setThai] = useState();
    const [english, setEnglish] = useState();

    const formIsValid = thai && english;

    const onClickDelete = ({ _id }) => {
        if (_id === -1) {
            setSelectedExpenseTypeId(_id);
            return;
        }
        if (!window.confirm("Delete expense type?")) {
            return;
        }
        handleApiData({ expenseTypeId: _id }, dispatch, "deleteExpenseType");
        resetFormFields();
        initData(dispatch);
    };

    const resetFormFields = () => {
        setSelectedExpenseTypeId(null);
        const funcs = [setThai, setEnglish];
        funcs.forEach(func => func(null));
    };

    const onClickSubmit = () => {
        /* safety measure - shouldn't be possible */
        if (!formIsValid) {
            window.alert("Please make sure all fields are valid.");
            return;
        }
        const data = {
            expenseTypeId: selectedExpenseTypeId,
            thai,
            english,
        };
        handleApiData(data, dispatch, "saveExpenseType");
        resetFormFields();
        initData(dispatch);
    };

    return (
        <div className="data-wrapper">
            <div
                style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    fontSize: "22px",
                }}
            >
                Edit / Enter Expense Types
            </div>

            {selectedExpenseTypeId ? (
                <div className="admin-staff-container">
                    <div className="admin-staff-row">
                        <div className="admin-input-container">
                            <div className="admin-staff-label">
                                Expense Type (Thai):
                            </div>
                            <input
                                className="admin-staff-input"
                                type="text"
                                placeholder="Pasah Thai"
                                value={thai ? thai : ""}
                                onChange={e => setThai(e.target.value)}
                                disabled={!selectedExpenseTypeId}
                            />
                        </div>
                        <div className="admin-input-container">
                            <div className="admin-staff-label">
                                Expense Type (English)
                            </div>
                            <input
                                className="admin-staff-input"
                                type="text"
                                placeholder="English language"
                                value={english ? english : ""}
                                onChange={e => setEnglish(e.target.value)}
                                disabled={!selectedExpenseTypeId}
                            />
                        </div>
                    </div>
                    <div
                        className="admin-staff-row"
                        style={{ marginTop: "5px" }}
                    >
                        <div
                            className="admin-input-container"
                            style={{ height: "36px" }}
                        >
                            <button
                                type="button"
                                className="admin-staff-button"
                                disabled={!formIsValid}
                                onClick={onClickSubmit}
                                style={{
                                    opacity: formIsValid ? 1 : ".4",
                                }}
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}

            <div style={{ marginTop: "20px", padding: "0 10px 0 10px" }}>
                <Datatable
                    settings={expenseTypesDt}
                    clickFunc={onClickDelete}
                />
            </div>
        </div>
    );
};

export default ExpenseTypes;
