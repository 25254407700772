import { useState } from "react";
import { useDispatch } from "../../../store/DataProvider";
import { handleLocalData } from "../../../store/actions";

const PriceRow = ({ sale, index }) => {
    const dispatch = useDispatch();
    const { price, thai, english } = sale;
    const [newPrice, setNewPrice] = useState(price);

    const handleInputChange = e => {
        const value = e.target.value;
        if (value && !Number(value)) return;
        setNewPrice(value);
    };

    const handleInputBlur = () => {
        handleLocalData(
            {
                type: "updatePrice",
                payload: { price: Number(newPrice), index },
            },
            dispatch,
        );
    };

    const handleInputSelect = e => {
        e.preventDefault();
        e.target.select();
    };

    const handleDelete = () => {
        handleLocalData(
            {
                type: "deleteSale",
                payload: { index },
            },
            dispatch,
        );
    };

    return (
        <div className="price-row">
            <input
                className="price-input"
                type="text"
                value={newPrice}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onClick={handleInputSelect}
            />
            <span className="price-label-thai">{thai}</span>
            <span className="price-label-english">{english}</span>
            <span className="trash-icon" onClick={handleDelete}>
                &#128465;
            </span>
        </div>
    );
};

export default PriceRow;
