import { useDispatch } from "../../../store/DataProvider";
import { handleLocalData } from "../../../store/actions";
import Animator from "./Animator";

const Staff = ({ staff, selectedStaff, alertStaff }) => {
    const dispatch = useDispatch();

    const onClick = staffId => {
        handleLocalData({ type: "selectedStaff", payload: staffId }, dispatch);
    };

    const renderedStaff = staff.map(obj => {
        const { _id: staffId, english } = obj;
        return (
            <div key={staffId}>
                <input
                    type="radio"
                    className="staff-radio-input"
                    name="staff-radio"
                    id={staffId}
                    checked={staffId === selectedStaff}
                    readOnly
                />
                <label
                    className="staff-radio-label"
                    htmlFor={staffId}
                    onClick={() => onClick(staffId)}
                >
                    {english}
                </label>
            </div>
        );
    });

    const setStaffAlert = async payload => {
        handleLocalData({ type: "alertStaff", payload }, dispatch);
    };

    return (
        <Animator activate={alertStaff} resetAction={setStaffAlert}>
            <div>
                <div className="staff-radio-group">{renderedStaff}</div>
            </div>
        </Animator>
    );
};

export default Staff;
