import { useDispatch } from "../../../store/DataProvider";
import { handleLocalData } from "../../../store/actions";
import Animator from "./Animator";

const PaymentType = ({
    paymentTypes,
    alertPaymentType,
    selectedPaymentType,
}) => {
    const dispatch = useDispatch();

    const onClick = paymentType => {
        handleLocalData(
            { type: "paymentType", payload: paymentType },
            dispatch,
        );
    };

    const renderedPaymentType = paymentTypes.map(obj => {
        const { name, english } = obj;
        return (
            <div key={name}>
                <input
                    key={name}
                    type="radio"
                    className="payment-radio-input"
                    name="payment-radio"
                    id={name}
                    checked={name === selectedPaymentType}
                    readOnly
                />
                <label
                    className="payment-radio-label"
                    htmlFor={name}
                    onClick={() => onClick(name)}
                >
                    {english}
                </label>
            </div>
        );
    });

    const setPaymentTypeAlert = payload => {
        handleLocalData({ type: "alertPaymentType", payload }, dispatch);
    };

    return (
        <Animator activate={alertPaymentType} resetAction={setPaymentTypeAlert}>
            <div className="payment-radio-group">{renderedPaymentType}</div>
        </Animator>
    );
};

export default PaymentType;
