import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { jwtDecode } from "jwt-decode";
import { useData, useDispatch } from "../../store/DataProvider";
import { handleLocalData } from "../../store/actions";

const CheckLogin = ({ children }) => {
    const { user } = useData();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cookies = new Cookies(null, { path: "/" });
    const auth = cookies.get("auth");

    useEffect(() => {
        if (!auth) {
            navigate("/auth");
        }
    }, [auth, navigate]);

    useEffect(() => {
        if (auth && !user._id) {
            const {
                sub: { _id, isSuperAdmin },
            } = jwtDecode(auth);
            handleLocalData(
                { type: "user", payload: { _id, isSuperAdmin } },
                dispatch,
            );
        }
    }, [user, auth, dispatch]);

    return children;
};

export default CheckLogin;
