import DataTable from "react-data-table-component";

const Datatable = ({ settings, loading, clickFunc, deleteFunc }) => {
    const {
        title,
        columns,
        data,
        expandable,
        defaultSortColumn,
        noHeader,
        noTableHead,
        striped,
        customStyles,
    } = settings;

    const isStriped = striped === false ? false : true;

    if (!columns) {
        return <h2>{loading ? loading : ""}</h2>;
    }

    const parsedColumns = columns.map(({ column, ...rest }) => {
        const selector = row => row[column];
        return { selector, ...rest };
    });

    const getExpandedContent = data => {
        if (!expandable) {
            return null;
        }
        const { expColumn, expType, expStyle } = expandable;

        switch (expType) {
            case "array":
                return data[expColumn].map(string => {
                    return (
                        <div style={expStyle} key={string}>
                            {string}
                        </div>
                    );
                });
            case "string":
                const string = data[expColumn];
                return (
                    <div style={expStyle} key={string}>
                        {string}
                    </div>
                );
            default:
                return null;
        }
    };
    const ExpandedComponent = ({ data }) => getExpandedContent(data);

    return (
        <DataTable
            title={title}
            columns={parsedColumns}
            data={data}
            dense
            striped={isStriped}
            responsive
            expandableRows
            expandOnRowClicked={deleteFunc ? true : false}
            expandOnRowDoubleClicked={deleteFunc ? false : true}
            expandableRowsHideExpander
            expandableRowsComponent={ExpandedComponent}
            defaultSortFieldId={defaultSortColumn}
            noHeader={noHeader}
            noTableHead={noTableHead}
            customStyles={customStyles}
            onRowClicked={clickFunc}
            onRowDoubleClicked={deleteFunc}
        />
    );
};

export default Datatable;
