import { useData } from "../../../store/DataProvider";
import Staff from "./Staff";
import PaymentType from "./PaymentType";
import CurrentSales from "./CurrentSales";
import SubmitRow from "./SubmitRow";
import Totals from "./Totals";

const Sales = () => {
    const {
        settings: { firstColumnPct, secondColumnHeight, paymentTypes },
        staff,
        alertStaff,
        selectedStaff,
        alertPaymentType,
        paymentType,
        currentSales,
        todayTotals,
    } = useData();
    const columnStyle = { flexBasis: `${100 - firstColumnPct}%` };
    const salesCntStyle = { height: `${secondColumnHeight}%` };
    const totalsCntStyle = { height: `${100 - secondColumnHeight}%` };

    return (
        <div className="second-column" style={columnStyle}>
            <div className="sales-container" style={salesCntStyle}>
                <div className="sale-container">
                    <div className="buttons-wrapper">
                        <Staff
                            staff={staff}
                            currentSales={currentSales}
                            alertStaff={alertStaff}
                            selectedStaff={selectedStaff}
                        />
                        <PaymentType
                            paymentTypes={paymentTypes}
                            alertPaymentType={alertPaymentType}
                            selectedPaymentType={paymentType}
                        />
                    </div>
                    <CurrentSales currentSales={currentSales} />
                    <SubmitRow />
                </div>
            </div>

            <div className="totals-container" style={totalsCntStyle}>
                <div className="total-container">
                    <Totals
                        todayTotals={todayTotals}
                        staff={staff}
                        selectedStaff={selectedStaff}
                    />
                </div>
            </div>
        </div>
    );
};

export default Sales;
