import { useEffect } from "react";
import ServicesList from "../utility/ServicesList";
import EditableService from "./EditableService";
import { useDispatch } from "../../store/DataProvider";
import { logoutAdmin } from "../../store/actions";

const Admin = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        logoutAdmin(dispatch);
    }, [dispatch]);

    return (
        <div className="columns-container">
            <div
                key="first-column"
                className="first-column"
                style={{ flexBasis: "60%", marginBottom: 0 }}
            >
                <ServicesList isAdmin={true} />
            </div>
            <div
                key="second-column"
                className="second-column"
                style={{
                    flexBasis: "40%",
                    marginBottom: 0,
                    backgroundColor: "white",
                }}
            >
                <EditableService />
            </div>
        </div>
    );
};

export default Admin;
