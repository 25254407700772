import { useState, useEffect } from "react";
import { useData, useDispatch } from "../../store/DataProvider";
import { handleApiData, initData, unsetAdminAlert } from "../../store/actions";

const EditableService = () => {
    const data = useData();
    const { editableService, adminAlert } = data;

    const { serviceId, price, thai, english, color } = editableService;
    const dispatch = useDispatch();

    const [selectedId, setSelectedId] = useState("");
    const [thaiLabel, setThaiLabel] = useState("");
    const [englishLabel, setEnglishLabel] = useState("");
    const [defaultPrice, setDefaultPrice] = useState("");
    const [selectedColor, setSelectedColor] = useState("");

    useEffect(() => {
        setSelectedId(serviceId ? serviceId : "");
        setThaiLabel(thai ? thai : "");
        setEnglishLabel(english ? english : "");
        setDefaultPrice(price ? price : "");
        setSelectedColor(color ? color : "");
    }, [serviceId, price, thai, english, color]);

    useEffect(() => {
        const { message, success } = adminAlert;
        if (message) {
            window.alert(message);
            unsetAdminAlert(dispatch);
            if (success) {
                setSelectedId("");
            }
        }
    }, [adminAlert, dispatch]);

    const onSaveService = () => {
        if (!(thaiLabel && englishLabel && defaultPrice)) {
            window.alert("All fields required.");
            return;
        }
        const serviceObj = {
            serviceId: selectedId,
            thai: thaiLabel,
            english: englishLabel,
            defaultPrice,
        };
        handleApiData(serviceObj, dispatch, "saveService");
        initData(dispatch);
    };

    const onResetService = () => {
        setThaiLabel(thai ? thai : "");
        setEnglishLabel(english ? english : "");
        setDefaultPrice(price ? price : "");
    };

    const onDeleteService = () => {
        const proceed = window.confirm("Permanently remove this service?");
        if (!proceed) {
            return;
        }
        handleApiData({ serviceId: selectedId }, dispatch, "deleteService");
        initData(dispatch);
    };

    const onChangePrice = e => {
        const val = e.target.value;
        if (isNaN(Number(val))) {
            return;
        }
        setDefaultPrice(val);
    };

    const onAddNewService = () => {
        setThaiLabel("");
        setEnglishLabel("");
        setDefaultPrice("");
        setSelectedId(-1);
        setSelectedColor("grey");
    };

    return (
        <div className="editable-service-container">
            {selectedId ? (
                <div
                    className="editable-service-card"
                    style={{ backgroundColor: selectedColor }}
                    disabled={true}
                >
                    <input
                        className="service-input"
                        type="text"
                        value={thaiLabel}
                        placeholder="Thai language"
                        onChange={e => setThaiLabel(e.target.value)}
                        style={{ fontSize: "22px" }}
                        autoFocus={true}
                    />
                    <input
                        className="service-input"
                        type="text"
                        value={englishLabel}
                        placeholder="English language"
                        onChange={e => setEnglishLabel(e.target.value)}
                        style={{ fontSize: "18px" }}
                    />
                    <input
                        className="service-input"
                        type="text"
                        value={defaultPrice}
                        placeholder="Default price"
                        onChange={onChangePrice}
                        style={{ fontSize: "18px" }}
                    />
                    <div className="edit-service-buttons">
                        {/* save */}
                        <button
                            className="edit-service-buttons"
                            style={{
                                fontSize: "14px",
                            }}
                            onClick={onSaveService}
                        >
                            &#128077;
                        </button>
                        {/* reset */}
                        <button
                            className="edit-service-buttons"
                            style={{ fontSize: "10px" }}
                            onClick={onResetService}
                        >
                            <span style={{ opacity: "70%" }}>&#10060;</span>
                        </button>
                        {/* delete */}
                        <button
                            className="edit-service-buttons"
                            style={{ marginRight: 0 }}
                            onClick={onDeleteService}
                        >
                            &#128465;
                        </button>
                    </div>
                </div>
            ) : null}

            <div
                className="add-new-service-button"
                style={{ marginTop: selectedId ? "20px" : "110px" }}
                onClick={onAddNewService}
            >
                &#8853;
            </div>
        </div>
    );
};

export default EditableService;
