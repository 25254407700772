import { v4 as uuid } from "uuid";
import Service from "./Service";
import { useData } from "../../store/DataProvider";

const ServicesList = ({ isAdmin }) => {
    const data = useData();
    const {
        services,
        settings: { serviceColumns, btnColors },
        selectedStaff,
    } = data;

    const spliced = [];
    const clone = [...services];
    while (clone.length > 0) {
        const items = clone.splice(0, serviceColumns);
        const last = items.slice(-1)[0];
        /* not sure why last element is undefined */
        if (last === undefined) {
            items.pop();
        }
        while (items.length < serviceColumns) {
            items.push({});
        }
        spliced.push(items);
    }

    let c = -1;
    let rowKey;
    return spliced.map(items => {
        rowKey = null;
        const serviceRow = items.map(service => {
            const _id = service._id;
            if (!rowKey) {
                rowKey = _id;
            }
            c++;
            const ind = c % btnColors.length;
            const color = btnColors[ind];
            const id = uuid();
            return (
                <Service
                    key={id}
                    service={service}
                    color={color}
                    selectedStaff={selectedStaff}
                    isAdmin={isAdmin}
                />
            );
        });
        return (
            <div key={rowKey} className="box-row">
                {serviceRow}
            </div>
        );
    });
};

export default ServicesList;
