import { mergeState } from "../services/utility";

export const reducer = (data, action) => {
    const { type, payload } = action;
    switch (type) {
        /* cannot use default here without renaming "data" */
        case "getData": {
            return mergeState(data, payload);
        }
        /* presumably these complexities could be handled within components */
        case "selectService": {
            const clone = [...data.currentSales];
            clone.push(payload);
            return mergeState(data, { currentSales: clone });
        }
        case "updatePrice": {
            const { price, index } = payload;
            const clone = [...data.currentSales];
            clone[index].price = price;
            return mergeState(data, { currentSales: clone });
        }
        case "deleteSale": {
            const { index } = payload;
            const clone = [...data.currentSales];
            clone.splice(index, 1);
            return mergeState(data, { currentSales: clone });
        }
        case "resetSale": {
            return mergeState(data, {
                currentSales: [],
                selectedStaff: "",
                paymentType: "",
            });
        }
        case "switchDay": {
            const { day, title } = payload;
            const { dailySales } = data;
            const newData = dailySales.dailySales[day];
            const newDaily = { ...dailySales, data: newData, title };
            return mergeState(data, {
                dailySales: newDaily,
            });
        }
        case "logout": {
            return mergeState(data, {
                loggedOut: payload,
                editableService: {},
            });
        }
        default: {
            /* when action type === data object property
             * and there's only one property to merge */
            return mergeState(data, { [type]: payload });
        }
    }
};
