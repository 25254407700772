import { useEffect } from "react";
import Cookies from "universal-cookie";
import { jwtDecode } from "jwt-decode";
import { useData, useDispatch } from "../../store/DataProvider";
import { handleLocalData } from "../../store/actions";
import { useNavigate } from "react-router-dom";
import Sales from "./Sales";
import Staff from "./Staff";
import Services from "./Services";
import Expenses from "./Expenses";

const AdminContainer = () => {
    const { user, adminArea } = useData();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user?.isSuperAdmin) {
            const cookies = new Cookies(null, { path: "/" });
            const auth = cookies.get("auth");
            const {
                sub: { _id, isSuperAdmin },
            } = jwtDecode(auth);

            if (!isSuperAdmin) {
                handleLocalData(
                    [
                        { type: "logout", payload: true },
                        { type: "user", payload: {} },
                    ],
                    dispatch,
                );
                window.alert("Unauthorized.");
                navigate("/auth");
            }

            /* if superAdmin refreshes page */
            handleLocalData(
                { type: "user", payload: { _id, isSuperAdmin } },
                dispatch,
            );
        }
    });

    const getAdmin = () => {
        switch (adminArea) {
            case "Staff":
                return <Staff />;
            case "Services":
                return <Services />;
            case "Expenses":
                return <Expenses />;
            default:
                return <Sales />;
        }
    };

    return (
        <div className="columns-container">
            {/* <Sales /> */}
            {/* <Staff /> */}
            {getAdmin()}
        </div>
    );
};

export default AdminContainer;
