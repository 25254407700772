const Totals = ({ todayTotals, staff: allStaff, selectedStaff }) => {
    const { total, staff } = todayTotals;
    const inputStyle = { width: "70px" };

    let staffRow = () => null;
    if (selectedStaff) {
        const staffName = allStaff.reduce((acc, { _id, thai, english }) => {
            if (_id === selectedStaff) {
                acc = [thai, english];
            }
            return acc;
        }, null);
        staffRow = () => {
            return (
                <div className="price-row totals-row">
                    <input
                        className="price-input staff-totals"
                        disabled
                        type="text"
                        value={staff[selectedStaff]}
                        style={inputStyle}
                    />
                    <span className="price-label-thai staff-totals">
                        Sales today by <strong>{staffName[0]}</strong>{" "}
                    </span>
                </div>
            );
        };
    }
    return (
        <>
            {staffRow()}
            <div className="price-row totals-row">
                <input
                    className="price-input grand-totals"
                    disabled
                    type="text"
                    value={total}
                    style={inputStyle}
                />
                <span className="price-label-thai grand-totals">
                    Total sales today
                </span>
            </div>
        </>
    );
};

export default Totals;
