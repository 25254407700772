import axios from "axios";

const BASE_URL = "/api";

export const handleApiData = async (params, dispatch, handler) => {
    /* handler can either be a params property or 3rd argument to function */
    if (handler) {
        params.handler = handler;
    }
    const url = `${BASE_URL}/handle`;
    const response = await axios.post(url, params);
    const { data } = response;
    /* data can either contain or consist of reducers array */
    const reducers = data.reducers ? data.reducers : data;
    for (const i in reducers) {
        const { type, payload } = reducers[i];
        dispatch({
            type,
            payload,
        });
    }
};

export const handleLocalData = async (data, dispatch) => {
    const reducers = Array.isArray(data) ? data : [data];
    for (const i in reducers) {
        const { type, payload } = reducers[i];
        dispatch({
            type,
            payload,
        });
    }
};

/* following convenience functions are invoked multiple times */
export const initData = async dispatch => {
    handleApiData({}, dispatch, "getData");
};

export const getMonthlySales = async (reqMonth, dispatch) => {
    handleApiData({ reqMonth }, dispatch, "getMonthlySales");
};

export const getMonthlyExpenses = async (month, dispatch) => {
    handleApiData({ month }, dispatch, "getMonthlyExpenses");
};

export const unsetAdminAlert = async dispatch => {
    handleLocalData(
        {
            type: "adminAlert",
            payload: "",
        },
        dispatch,
    );
};

/* one suspects this is obsolete */
export const logoutAdmin = async dispatch => {
    handleLocalData(
        [
            {
                type: "adminAlert",
                payload: {},
            },
            {
                type: "adminLoggedIn",
                payload: false,
            },
        ],
        dispatch,
    );
};
