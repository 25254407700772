import dayjs from "dayjs";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Datatable from "./Datatable";
import { useDispatch } from "../../store/DataProvider";
import { handleApiData } from "../../store/actions";

import "react-datepicker/dist/react-datepicker.css";

const Commissions = ({
    commissionTotals,
    selectedStaffId,
    selectedStaffName,
    selectedMonth,
    payments,
    commissionRate,
}) => {
    const customStyles = {
        headRow: {
            style: {
                fontWeight: "bold",
            },
        },
        rows: {
            style: {
                fontSize: "14px",
            },
        },
    };
    const paymentsColumns = [
        {
            name: "Paid Date",
            column: "day",
            sortable: "true",
            width: "50%",
            compact: "true",
            center: "true",
        },
        {
            name: "Amount Paid",
            column: "paid",
            sortable: "true",
            width: "50%",
            compact: "true",
            center: "true",
        },
    ];
    const paymentsDt = {
        columns: paymentsColumns,
        data: payments,
        customStyles,
    };

    const dispatch = useDispatch();
    const today = dayjs().toISOString();
    const [commissionDate, setCommissionDate] = useState(today);
    const [commissionPayment, setCommissionPayment] = useState("");
    const [buttonMessage, setButtonMessage] = useState();
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const getButtonMessage = () => {
        const missing = [];
        if (!commissionPayment) {
            missing.push("payment amount");
        }
        if (!commissionDate) {
            missing.push("date");
        }
        if (missing.length) {
            return { disable: true, message: `Enter ${missing.join(" and ")}` };
        } else {
            return {
                disable: false,
                message: `Pay ${selectedStaffName} THB ${commissionPayment}`,
            };
        }
    };

    useEffect(() => {
        const { disable, message } = getButtonMessage();
        setButtonMessage(message);
        setButtonDisabled(disable);
    }, [commissionPayment, commissionDate]);

    const onChangePayment = e => {
        const value = e.target.value;
        if (value && !Number(value)) {
            return;
        }
        setCommissionPayment(value);
    };

    const dtpMinMax = [
        dayjs(selectedMonth?.value).startOf("month").valueOf(),
        dayjs(selectedMonth?.value).endOf("month").valueOf(),
    ];

    const handleSubmitPayment = e => {
        e.preventDefault();
        const payment = {
            staffId: selectedStaffId,
            commissionDate,
            commissionPayment,
        };
        setButtonDisabled(true);
        setCommissionDate("");
        setCommissionPayment("");
        handleApiData(payment, dispatch, "submitCommission");
        setCommissionDate(today);
    };

    const handleDeletePayment = ({ _id }) => {
        if (
            window.confirm(
                "Are you sure you want to delete this commission payment?",
            )
        ) {
            handleApiData(
                { _id, month: selectedMonth.value },
                dispatch,
                "deleteCommission",
            );
        }
    };

    return (
        <>
            <Datatable settings={commissionTotals} />
            {commissionRate > 0 ? (
                <div
                    className="commission-submit-row"
                    style={{
                        borderBottom: payments.length
                            ? "thin solid rgba(0,0,0,.12)"
                            : "none",
                    }}
                >
                    <div className="spacer-div">&nbsp;</div>
                    <div className="commission-submit-container">
                        <span
                            className="commission-text"
                            style={{ marginRight: "2px" }}
                        >
                            Date:
                        </span>
                        <DatePicker
                            selected={commissionDate}
                            onChange={date => setCommissionDate(date)}
                            dateFormat="YYYY-MM-dd"
                            placeholderText="Select date"
                            minDate={dtpMinMax[0]}
                            maxDate={dtpMinMax[1]}
                        />
                        <span
                            className="commission-text"
                            style={{ marginRight: "10px" }}
                        >
                            Amount:
                        </span>
                        <input
                            className="commission-input"
                            type="text"
                            placeholder="Payment"
                            value={commissionPayment}
                            onChange={onChangePayment}
                        />
                    </div>
                    <div style={{ marginTop: "5px" }}>
                        <button
                            type="button"
                            className="submit-commission"
                            disabled={buttonDisabled}
                            onClick={handleSubmitPayment}
                            style={{
                                opacity: buttonDisabled ? 0.4 : 1,
                                cursor: buttonDisabled ? "default" : "cursor",
                            }}
                        >
                            {buttonMessage}
                        </button>
                    </div>
                    <div
                        className="spacer-div"
                        style={{
                            borderTop: "thin solid rgba(0,0,0,.12)",
                            marginTop: "10px",
                        }}
                    >
                        &nbsp;
                    </div>
                    {payments ? (
                        <Datatable
                            settings={paymentsDt}
                            clickFunc={handleDeletePayment}
                        />
                    ) : null}
                </div>
            ) : null}
        </>
    );
};

export default Commissions;
