import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Select from "react-select";
import Datatable from "./Datatable";
import { useData, useDispatch } from "../../store/DataProvider";
import {
    handleApiData,
    handleLocalData,
    getMonthlySales,
    initData,
    logoutAdmin,
    unsetAdminAlert,
} from "../../store/actions";

const Admin = () => {
    const data = useData();
    const dispatch = useDispatch();
    const { staffOptions, monthlySales, dailySales, adminAlert } = data;

    useEffect(() => {
        getMonthlySales(null, dispatch);
    }, []);

    useEffect(() => {
        logoutAdmin(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (staffOptions.length === 0) {
            initData(dispatch);
        }
    }, [staffOptions, dispatch]);

    useEffect(() => {
        const { message } = adminAlert;
        if (message) {
            window.alert(message);
            unsetAdminAlert(dispatch);
            getMonthlySales(null, dispatch);
        }
    }, [adminAlert, dispatch]);

    /* Unsure why cloning (...) necessary here
     * think it has to do with handling undefined async data */
    const totals = { ...monthlySales.totals };
    const { options: monthOptions, selected } = { ...monthlySales.selectObj };

    const [selectedMonth, setSelectedMonth] = useState();
    const [selectedDay, setSelectedDay] = useState();

    useEffect(() => {
        setSelectedMonth(selected);
    }, [selected]);

    const onSelectMonth = opt => {
        setSelectedMonth(opt);
        getMonthlySales(opt.value, dispatch);
    };

    const handleDailyClick = row => {
        const { day } = row;
        const { format } = dailySales;
        const title = dayjs(day).format(format);
        handleLocalData(
            { type: "switchDay", payload: { day, title } },
            dispatch,
        );
        setSelectedDay(day);
    };

    const handleDeleteSale = row => {
        const proceed = window.confirm("Do you want to delete this sale?");
        if (proceed) {
            const { _id } = row;
            handleApiData({ saleId: _id }, dispatch, "deleteSale");
            const day = selectedDay ? `|${selectedDay}` : "";
            getMonthlySales(`${selectedMonth.value}${day}`, dispatch);
        }
    };

    return (
        <div className="admin-wrapper">
            <div className="admin-column">
                <div className="select-wrapper">
                    <div className="spacer-div">&nbsp;</div>
                    <Select
                        value={selectedMonth}
                        onChange={opt => onSelectMonth(opt)}
                        options={monthOptions}
                    />
                </div>
                <div className="data-wrapper">
                    <Datatable
                        settings={monthlySales}
                        clickFunc={handleDailyClick}
                    />
                </div>
                <div
                    style={{
                        flex: "0 1 15px",
                        backgroundColor: "white",
                        padding: "7px",
                        borderTop: "3px solid lightgrey",
                        borderRight: "10px solid lightgrey",
                        borderBottom: "10px solid lightgrey",
                    }}
                >
                    <Datatable settings={totals} loading="Loading ..." />
                </div>
            </div>

            <div className="admin-column">
                <div className="data-wrapper" style={{ borderRight: 0 }}>
                    <div style={{ marginTop: "6px" }}>
                        <Datatable
                            settings={dailySales}
                            deleteFunc={handleDeleteSale}
                        />
                    </div>
                </div>
                <div
                    style={{
                        flex: "0 1 0px",
                        backgroundColor: "white",
                        borderTop: "0px solid lightgrey",
                        borderRight: "10px solid lightgrey",
                        borderBottom: "10px solid lightgrey",
                    }}
                ></div>
            </div>
        </div>
    );
};

export default Admin;
