import { useEffect, useState } from "react";
import Select from "react-select";
import Commissions from "./Commissions";
import { useData, useDispatch } from "../../store/DataProvider";
import {
    handleApiData,
    logoutAdmin,
    initData,
    getMonthlySales,
    unsetAdminAlert,
} from "../../store/actions";

const StaffAdmin = () => {
    const data = useData();
    const dispatch = useDispatch();
    const { staffObj, staffOptions, adminAlert, monthlySales, commission } =
        data;

    const statusOptions = [
        { value: true, label: "Active" },
        { value: false, label: "Inactive" },
    ];

    useEffect(() => {
        logoutAdmin(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (staffOptions.length === 0) {
            initData(dispatch);
        }
    }, [staffOptions, dispatch]);

    useEffect(() => {
        if (monthlySales.length === 0) {
            getMonthlySales(null, dispatch);
        }
    }, [monthlySales, dispatch]);

    /* Unsure why cloning (...) necessary here
     * think it has to do with handling undefined async data */
    const { options: monthOptions, selected } = { ...monthlySales.selectObj };

    const [selectedStaffOpt, setSelectedStaffOpt] = useState();
    const [selectedStaffId, setSelectedStaffId] = useState();
    const [selectedStaffName, setSelectedStaffName] = useState();
    const [thai, setThai] = useState();
    const [english, setEnglish] = useState();
    const [username, setUsername] = useState();
    const [commissionRate, setCommissionRate] = useState();
    const [statusOpt, setStatusOpt] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState();
    const [commissionTotals, setCommissionTotals] = useState({});
    const [payments, setPayments] = useState({});

    const formIsValid =
        selectedStaffId &&
        thai &&
        english &&
        username &&
        username.length > 2 &&
        statusOpt &&
        Number(commissionRate) >= 0 &&
        Number(commissionRate) <= 100 &&
        commissionRate !== "";

    useEffect(() => {
        setSelectedMonth(selected);
    }, [selected]);

    /* RECONCILE REDUNDANCY WITH onSelectStaff BELOW - set up helper function */
    useEffect(() => {
        if (selectedStaffId) {
            const { totals, columns, customStyles, striped, payments } =
                commission;
            const data = totals[selectedStaffId];
            setCommissionTotals({
                data,
                columns,
                customStyles,
                striped,
            });
            setPayments(payments[selectedStaffId]);
        } else {
            setCommissionTotals({});
        }
    }, [selectedStaffId, commission]);

    useEffect(() => {
        const { message } = adminAlert;
        if (message) {
            window.alert(message);
            unsetAdminAlert(dispatch);
        }
    }, [adminAlert, dispatch]);

    const onSelectMonth = opt => {
        setSelectedMonth(opt);
        getMonthlySales(opt.value, dispatch);
    };

    const onSelectStatus = opt => {
        if (!selectedStaffId) {
            return null;
        }
        setStatusOpt(opt);
    };

    const getStatusOpt = selectedStaffId => {
        const active = staffObj[selectedStaffId].active;
        const option = statusOptions.filter(opt => opt.value === active);
        return option[0];
    };

    const onSelectStaff = opt => {
        setSelectedStaffOpt(opt);
        const { value, label } = opt;
        setSelectedStaffId(value);
        setSelectedStaffName(label);
        const thisStaff = staffObj[value];
        setEnglish(thisStaff.english);
        setThai(thisStaff.thai);
        setUsername(thisStaff.username);
        setCommissionRate(thisStaff.commission * 100);
        setStatusOpt(getStatusOpt(value));

        const { totals, columns, customStyles, striped, payments } = commission;
        const data = totals[value];
        setCommissionTotals({
            data,
            columns,
            customStyles,
            striped,
        });
        setPayments(payments[value]);
    };

    const onClickSubmit = () => {
        /* safety measure - shouldn't be possible */
        if (!formIsValid) {
            window.alert("Please make sure all fields are valid.");
        }
        const data = {
            staffId: selectedStaffId,
            thai,
            english,
            username,
            active: statusOpt.value,
            commission: commissionRate === 0 ? 0 : commissionRate / 100,
        };
        handleApiData(data, dispatch, "upsertStaff");
        resetFormFields();
        initData(dispatch);
    };

    const resetFormFields = () => {
        setSelectedStaffOpt(null);
        const funcs = [
            setThai,
            setEnglish,
            setUsername,
            setCommissionRate,
            setStatusOpt,
            setSelectedStaffId,
        ];
        funcs.forEach(func => func(null));
    };

    const onClickReset = () => {
        if (!selectedStaffId) {
            window.alert("Please select staff.");
            return;
        }
        handleApiData({ staffId: selectedStaffId }, dispatch, "resetPassword");
    };

    return (
        <div className="admin-wrapper">
            <div className="admin-column">
                <div className="data-wrapper">
                    <div style={{ marginTop: "20px", fontSize: "22px" }}>
                        Edit / Enter Staff
                    </div>

                    <div className="select-wrapper" style={{ borderRight: 0 }}>
                        <div className="spacer-div">&nbsp;</div>
                        <Select
                            value={selectedStaffOpt}
                            options={staffOptions}
                            onChange={opt => onSelectStaff(opt)}
                            placeholder="Select staff"
                        />
                    </div>

                    {selectedStaffId ? (
                        <div className="admin-staff-container">
                            <div className="admin-staff-row">
                                <div className="admin-input-container">
                                    <div className="admin-staff-label">
                                        Name (Thai):
                                    </div>
                                    <input
                                        className="admin-staff-input"
                                        type="text"
                                        placeholder="Pasah Thai"
                                        value={thai ? thai : ""}
                                        onChange={e => setThai(e.target.value)}
                                        disabled={!selectedStaffId}
                                    />
                                </div>
                                <div className="admin-input-container">
                                    <div className="admin-staff-label">
                                        Name (English)
                                    </div>
                                    <input
                                        className="admin-staff-input"
                                        type="text"
                                        placeholder="English language"
                                        value={english ? english : ""}
                                        onChange={e =>
                                            setEnglish(e.target.value)
                                        }
                                        disabled={!selectedStaffId}
                                    />
                                </div>
                            </div>
                            <div className="admin-staff-row">
                                <div
                                    className="admin-input-container"
                                    style={{
                                        width: "200px",
                                    }}
                                >
                                    <div className="admin-staff-label">
                                        Username:
                                    </div>
                                    <input
                                        className="admin-staff-input"
                                        type="text"
                                        placeholder="Login username"
                                        value={username ? username : ""}
                                        onChange={e =>
                                            setUsername(e.target.value)
                                        }
                                        disabled={!selectedStaffId}
                                    />
                                </div>
                                <div
                                    className="admin-input-container"
                                    style={{
                                        width: "60px",
                                    }}
                                >
                                    <div className="admin-staff-label">
                                        Comm:
                                    </div>
                                    <input
                                        className="admin-staff-input"
                                        type="text"
                                        placeholder="%"
                                        /* support value===0 */
                                        value={
                                            commissionRate?.toString().length >
                                            0
                                                ? commissionRate
                                                : ""
                                        }
                                        onChange={e =>
                                            setCommissionRate(e.target.value)
                                        }
                                        disabled={!selectedStaffId}
                                        style={{
                                            textAlign: "center",
                                        }}
                                    />
                                </div>
                                <div className="admin-input-container">
                                    <div
                                        className="admin-staff-label"
                                        style={{ marginBottom: "5px" }}
                                    >
                                        Status:
                                    </div>
                                    <div
                                        style={{
                                            textAlign: "left",
                                            width: "125px",
                                        }}
                                    >
                                        <Select
                                            options={statusOptions}
                                            value={statusOpt}
                                            onChange={opt =>
                                                onSelectStatus(opt)
                                            }
                                            placeholder="Select"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="admin-staff-row"
                                style={{ marginTop: "5px" }}
                            >
                                <div
                                    className="admin-input-container"
                                    style={{
                                        height: "36px",
                                    }}
                                >
                                    <button
                                        type="button"
                                        className="admin-reset-button"
                                        onClick={onClickReset}
                                    >
                                        Reset Password
                                    </button>
                                </div>
                                <div
                                    className="admin-input-container"
                                    style={{ height: "36px" }}
                                >
                                    <button
                                        type="button"
                                        className="admin-staff-button"
                                        disabled={!formIsValid}
                                        onClick={onClickSubmit}
                                        style={{
                                            opacity: formIsValid ? 1 : ".4",
                                        }}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div
                    style={{
                        borderRight: "10px solid lightgrey",
                        borderBottom: "10px solid lightgrey",
                    }}
                />
            </div>

            <div className="admin-column" style={{ width: "30%" }}>
                <div
                    style={{
                        marginTop: "20px",
                        marginBottom: "9px",
                        fontSize: "22px",
                    }}
                >
                    Commissions
                </div>
                <div className="select-wrapper" style={{ borderRight: 0 }}>
                    <Select
                        value={selectedMonth}
                        onChange={opt => onSelectMonth(opt)}
                        options={monthOptions}
                    />
                </div>

                <div className="data-wrapper" style={{ borderRight: 0 }}>
                    {selectedStaffId && payments ? (
                        <Commissions
                            commissionTotals={commissionTotals}
                            selectedStaffId={selectedStaffId}
                            selectedStaffName={selectedStaffName}
                            selectedMonth={selectedMonth}
                            payments={payments}
                            commissionRate={
                                staffObj[selectedStaffId].commission
                            }
                        />
                    ) : (
                        <div style={{ marginTop: "15px" }}>
                            {selectedStaffId ? null : "Please select staff"}
                        </div>
                    )}
                </div>

                <div
                    style={{
                        flex: "0 1 0px",
                        backgroundColor: "white",
                        padding: "0px",
                        borderRight: "0",
                        borderBottom: "10px solid lightgrey",
                    }}
                ></div>
            </div>
        </div>
    );
};

export default StaffAdmin;
