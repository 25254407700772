import dayjs from "dayjs";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Datatable from "./Datatable";
import { useData, useDispatch } from "../../store/DataProvider";
import {
    handleApiData,
    getMonthlyExpenses,
    unsetAdminAlert,
} from "../../store/actions";
import DatepickerInput from "./DatepickerInput";

import "react-datepicker/dist/react-datepicker.css";

const MonthlyExpenses = ({
    selected,
    monthOptions,
    expenseTypeOptions,
    adminAlert,
}) => {
    const data = useData();
    const { expensesDt } = data;
    const dispatch = useDispatch();
    const today = dayjs().toISOString();

    const [monthlyExpenses, setMonthlyExpenses] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [expenseDate, setExpenseDate] = useState(today);
    const [expenseType, setExpenseType] = useState("");
    const [expenseAmount, setExpenseAmount] = useState("");
    const [buttonMessage, setButtonMessage] = useState();
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const getButtonMessage = () => {
        const missing = [];
        if (!expenseAmount) {
            missing.push("expense amount");
        }
        if (!expenseDate) {
            missing.push("date");
        }
        if (missing.length) {
            return { disable: true, message: `Enter ${missing.join(" and ")}` };
        } else {
            return {
                disable: false,
                message: `Add expense THB ${expenseAmount}`,
            };
        }
    };

    useEffect(() => {
        const { value: month } = selected;
        setSelectedMonth(selected);
        getMonthlyExpenses(month, dispatch);
    }, [selected]);

    useEffect(() => {
        setMonthlyExpenses(expensesDt);
    }, [expensesDt]);

    useEffect(() => {
        const { disable, message } = getButtonMessage();
        setButtonMessage(message);
        setButtonDisabled(disable);
    }, [expenseAmount, expenseDate]);

    useEffect(() => {
        const { message } = adminAlert;
        if (message) {
            unsetAdminAlert(dispatch);
        }
    }, [adminAlert, dispatch]);

    const onSelectMonth = opt => {
        setSelectedMonth(opt);
        const { value: month } = opt;
        const expDate = dayjs(month).toISOString();
        /* datepicker using button needs a default date in given month */
        setExpenseDate(expDate);
        getMonthlyExpenses(month, dispatch);
    };

    const onChangeExpense = e => {
        const value = e.target.value;
        if (value && !Number(value)) {
            return;
        }
        setExpenseAmount(value);
    };

    const onSelectExpenseType = opt => {
        setExpenseType(opt);
    };

    const dtpMinMax = [
        dayjs(selectedMonth?.value).startOf("month").valueOf(),
        dayjs(selectedMonth?.value).endOf("month").valueOf(),
    ];

    const handleSubmitExpense = e => {
        e.preventDefault();
        const data = {
            type: expenseType.label,
            expenseDate,
            amount: expenseAmount,
        };
        handleApiData(data, dispatch, "saveExpense");
        setButtonDisabled(true);
        setExpenseAmount("");
        setExpenseType("");
        const month = dayjs(expenseDate).format("YYYY-MM");
        getMonthlyExpenses(month, dispatch);
    };

    const handleDeletePayment = ({ _id }) => {
        if (window.confirm("Are you sure you want to delete this expense?")) {
            handleApiData({ expenseId: _id }, dispatch, "deleteExpense");
        }
        const month = dayjs(expenseDate).format("YYYY-MM");
        getMonthlyExpenses(month, dispatch);
    };

    return (
        <>
            <div
                style={{
                    marginTop: "20px",
                    marginBottom: "9px",
                    fontSize: "22px",
                }}
            >
                Monthly Expenses
            </div>

            <div className="select-wrapper" style={{ borderRight: 0 }}>
                <Select
                    value={selectedMonth}
                    onChange={onSelectMonth}
                    options={monthOptions}
                />
            </div>

            <div style={{ marginLeft: "7px", marginRight: "7px" }}>
                <div style={{ marginBottom: "10px" }}>
                    <div className="spacer-div">&nbsp;</div>
                    <div
                        className="expenses-submit-container"
                        style={{
                            justifyContent: "space-between",
                            paddingTop: "10px",
                        }}
                    >
                        <div
                            className="expenses-entry"
                            style={{ paddingRight: "7px" }}
                        >
                            <DatePicker
                                selected={expenseDate}
                                onChange={date => setExpenseDate(date)}
                                dateFormat="YYYY-MM-dd"
                                placeholderText="Select date"
                                minDate={dtpMinMax[0]}
                                maxDate={dtpMinMax[1]}
                                customInput={
                                    <DatepickerInput className="datepicker-input" />
                                }
                                wrapperClassName="datepicker-wrapper"
                            />
                        </div>

                        <div
                            className="expenses-entry"
                            style={{
                                flex: "3 0",
                                textAlign: "left",
                                paddingRight: "7px",
                            }}
                        >
                            <Select
                                options={expenseTypeOptions}
                                value={expenseType}
                                onChange={opt => onSelectExpenseType(opt)}
                                styles={{
                                    control: baseStyles => ({
                                        ...baseStyles,
                                        width: "205px",
                                        boxShadow: "0 2px 4px rgba(0,0,0, .2)",
                                        border: "0",
                                        height: "100%",
                                    }),
                                }}
                            />
                        </div>

                        <div className="expenses-entry">
                            <input
                                className="expenses-input"
                                type="text"
                                placeholder="Enter Expense"
                                value={expenseAmount}
                                onChange={onChangeExpense}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                        <button
                            type="button"
                            className="submit-commission"
                            disabled={buttonDisabled}
                            onClick={handleSubmitExpense}
                            style={{
                                opacity: buttonDisabled ? 0.4 : 1,
                                cursor: buttonDisabled ? "default" : "cursor",
                            }}
                        >
                            {buttonMessage}
                        </button>
                    </div>
                    <div
                        className="spacer-div"
                        style={{
                            borderTop: "thin solid rgba(0,0,0,.12)",
                            marginTop: "10px",
                        }}
                    >
                        &nbsp;
                    </div>
                </div>
                {monthlyExpenses ? (
                    <Datatable
                        settings={monthlyExpenses}
                        deleteFunc={handleDeletePayment}
                    />
                ) : null}
            </div>
        </>
    );
};

export default MonthlyExpenses;
