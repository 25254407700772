import { useEffect, useState } from "react";
import Datatable from "./Datatable";
import ExpenseTypes from "./ExpenseTypes";
import MonthlyExpenses from "./MonthlyExpenses";
import { useData, useDispatch } from "../../store/DataProvider";
import {
    logoutAdmin,
    initData,
    getMonthlySales,
    unsetAdminAlert,
} from "../../store/actions";

const Expenses = () => {
    const dispatch = useDispatch();
    const data = useData();
    const {
        expenseTypesDt,
        expenseTypeOptions,
        adminAlert,
        monthlySales,
        profitDt,
    } = data;

    /* Unsure why cloning (...) necessary here
     * think it has to do with handling undefined async data */
    const { options: monthOptions, selected } = { ...monthlySales.selectObj };

    const [selectedMonth, setSelectedMonth] = useState();

    useEffect(() => {
        setSelectedMonth(selected);
    }, [selected]);

    useEffect(() => {
        logoutAdmin(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (!expenseTypesDt) {
            initData(dispatch);
        }
    }, [expenseTypesDt, dispatch]);

    useEffect(() => {
        if (monthlySales.length === 0) {
            getMonthlySales(null, dispatch);
        }
    }, [monthlySales, dispatch]);

    useEffect(() => {
        const { message } = adminAlert;
        if (message) {
            window.alert(message);
            unsetAdminAlert(dispatch);
        }
    }, [adminAlert, dispatch]);

    return (
        <div className="admin-wrapper">
            <div className="admin-column">
                <ExpenseTypes expenseTypesDt={expenseTypesDt} />
                <div
                    style={{
                        borderRight: "10px solid lightgrey",
                        borderBottom: "10px solid lightgrey",
                    }}
                />
            </div>

            <div className="admin-column" style={{ width: "30%" }}>
                <div className="data-wrapper" style={{ borderRight: 0 }}>
                    {selectedMonth ? (
                        <MonthlyExpenses
                            selected={selectedMonth}
                            monthOptions={monthOptions}
                            expenseTypeOptions={expenseTypeOptions}
                            adminAlert={adminAlert}
                        />
                    ) : (
                        <div style={{ marginTop: "15px" }}>
                            {selectedMonth ? null : "Please select the month"}
                        </div>
                    )}
                </div>

                <div
                    style={{
                        flex: "0 1 0px",
                        backgroundColor: "white",
                        padding: "0px",
                        borderRight: "0",
                        borderBottom: "10px solid lightgrey",
                        borderTop: "3px solid lightgrey",
                    }}
                >
                    {profitDt ? (
                        <Datatable settings={profitDt} loading="Loading ..." />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Expenses;
