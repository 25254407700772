import { useDispatch } from "../../store/DataProvider";
import { handleLocalData } from "../../store/actions";

const AdminOptions = ({ adminArea }) => {
    const dispatch = useDispatch();

    const onClick = adminArea => {
        // handleLocalData({ type: "setadminarea", payload: adminArea }, dispatch);
        handleLocalData({ type: "adminArea", payload: adminArea }, dispatch);
    };

    const options = ["Sales", "Staff", "Services", "Expenses"];
    const selectedOpt = adminArea ? adminArea : "Sales";

    const radioOptions = options.map(option => {
        return (
            <div key={option}>
                <input
                    type="radio"
                    className="admin-radio-input"
                    name="opt-radio"
                    id={option}
                    checked={option === selectedOpt}
                    readOnly
                />
                <label
                    className="admin-radio-label"
                    htmlFor={option}
                    onClick={() => onClick(option)}
                >
                    {option}
                </label>
            </div>
        );
    });
    return (
        <div>
            <div className="admin-radio-group">{radioOptions}</div>
        </div>
    );
};

export default AdminOptions;
