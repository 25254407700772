import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DataProvider } from "./store/DataProvider";
import MainContainer from "./components/MainContainer";
import Header from "./components/Header";
import POS from "./components/pos/POS";
import AdminContainer from "./components/admin/AdminContainer";
import Authenticate from "./components/auth/Authenticate";
import CheckLogin from "./components/auth/CheckLogin";

import "./App.css";

const App = () => {
    return (
        <main className="app">
            <DataProvider>
                <BrowserRouter>
                    <CheckLogin>
                        <Routes>
                            <Route
                                path="/"
                                exact
                                element={
                                    <MainContainer>
                                        <Header />
                                        <POS />
                                    </MainContainer>
                                }
                            ></Route>
                            <Route
                                path="/admin"
                                exact
                                element={
                                    <MainContainer>
                                        <Header type="admin" />
                                        <AdminContainer />
                                    </MainContainer>
                                }
                            ></Route>
                            <Route
                                path="/auth"
                                exact
                                element={
                                    <>
                                        <Header />
                                        <Authenticate />
                                    </>
                                }
                            ></Route>
                        </Routes>
                    </CheckLogin>
                </BrowserRouter>
            </DataProvider>
        </main>
    );
};

export default App;
