import { useDispatch } from "../../store/DataProvider";
import { handleLocalData } from "../../store/actions";

const Service = ({ service, color, selectedStaff, isAdmin }) => {
    const { _id: serviceId, defaultPrice, thai, english } = service;
    const dispatch = useDispatch();
    const onClick = () => {
        let payload;
        if (isAdmin) {
            payload = {
                serviceId,
                price: defaultPrice,
                thai,
                english,
                color,
            };
            handleLocalData({ type: "editableService", payload }, dispatch);
        } else {
            payload = {
                serviceId,
                staffId: selectedStaff,
                price: defaultPrice,
                thai,
                english,
            };
            if (selectedStaff) {
                handleLocalData({ type: "selectService", payload }, dispatch);
            } else {
                handleLocalData(
                    { type: "alertStaff", payload: true },
                    dispatch,
                );
            }
        }
    };

    const style = { backgroundColor: color };
    if (serviceId) {
        return (
            <button className="card" style={style} onClick={onClick}>
                <div className="thai-label">{thai}</div>
                <div className="english-label">{english}</div>
            </button>
        );
    } else {
        return <div className="hidden-card" />;
    }
};

export default Service;
