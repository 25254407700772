import { useEffect } from "react";
import { useData, useDispatch } from "../../store/DataProvider";
import { initData } from "../../store/actions";
import Services from "./services/Services";
import Sales from "./sales/Sales";

const POS = () => {
    const { services } = useData();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!services.length) {
            initData(dispatch);
        }
    }, [services, dispatch]);

    if (!services.length) {
        return null;
    }

    return (
        <div className="columns-container">
            <Services />
            <Sales />
        </div>
    );
};

export default POS;
