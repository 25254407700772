import { createContext, useReducer, useContext } from "react";
import { reducer } from "./reducer";

const DataContext = createContext(null);
export const useData = () => {
    return useContext(DataContext);
};

const DispatchContext = createContext(null);
export const useDispatch = () => {
    return useContext(DispatchContext);
};

export const DataProvider = ({ children }) => {
    const [data, dispatch] = useReducer(reducer, {}, initData);
    return (
        <DataContext.Provider value={data}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </DataContext.Provider>
    );
};

const modalStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

const initData = () => {
    return {
        settings: {},
        services: [],
        staff: [],
        staffOptions: [],
        expenseTypes: [],
        expenseTypeOptions: [],
        currentSales: [],
        todayTotals: {},
        monthlySales: [],
        dailySales: [],
        commission: {},
        user: {},
        adminLoggedIn: false,
        adminAlert: {},
        editableService: {},
        userAlert: "",
        adminArea: "Sales",
        modalStyle,
    };
};
