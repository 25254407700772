import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { handleLocalData } from "../store/actions";
import { useData, useDispatch } from "../store/DataProvider";
import AdminOptions from "./admin/AdminOptions";

const Header = ({ type }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const dispatch = useDispatch();
    const {
        user: { isSuperAdmin },
        adminArea,
    } = useData();
    const cookies = new Cookies(null, { path: "/" });

    const onClickLogout = e => {
        e.preventDefault();
        cookies.remove("auth", { path: "/" });
        handleLocalData(
            [
                { type: "logout", payload: true },
                { type: "user", payload: {} },
            ],
            dispatch,
        );
        navigate("/auth");
    };

    const onClickNav = (e, path) => {
        e.preventDefault();
        navigate(path);
    };

    const getAdminLink = () => {
        if (!isSuperAdmin) {
            return null;
        }
        const path = pathname === "/admin" ? "/" : "/admin";
        const label = pathname === "/admin" ? "home" : "admin";
        return (
            <h2 className="header-link" onClick={e => onClickNav(e, path)}>
                {label}
            </h2>
        );
    };

    const getLogoutLink = () => {
        if (pathname === "/admin") {
            return null;
        }
        return (
            <h2 className="header-link" onClick={onClickLogout}>
                logout
            </h2>
        );
    };

    return (
        <div
            className="header"
            /* cannot explain the following */
            style={{ paddingBottom: adminArea === "Services" ? "5px" : 0 }}
        >
            <div className="header-wrapper">
                <div className="header-buffer" />
                {type === "admin" ? (
                    <AdminOptions adminArea={adminArea} />
                ) : (
                    <h2 className="header-text">
                        namjing beautiful hair salon
                    </h2>
                )}
                <div className="header-buffer">
                    {getLogoutLink()}
                    {getAdminLink()}
                </div>
            </div>
        </div>
    );
};

export default Header;
