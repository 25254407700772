import ServicesList from "../../utility/ServicesList";
import { useData } from "../../../store/DataProvider";

const Services = () => {
    const data = useData();
    const {
        settings: { firstColumnPct },
    } = data;
    const columnStyle = { flexBasis: `${firstColumnPct}%` };

    return (
        <div key="first-column" className="first-column" style={columnStyle}>
            <ServicesList />
        </div>
    );
};

export default Services;
